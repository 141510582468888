import { Popover } from 'antd';
import styled from 'styled-components';

export const Container = styled(Popover)``;

export const RemoveButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin-left: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  transition: all 200ms ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const Label = styled.div<{ $hasValue: boolean }>`
  position: relative;
  color: ${({ $hasValue, theme }) => ($hasValue ? '#00000073' : '')};

  &::after {
    content: ':';
    display: ${({ $hasValue }) => !$hasValue && 'none'};
  }
`;
