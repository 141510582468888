import React, { FC, useState } from 'react';

import { MoreOutlined } from '@ant-design/icons';
import { theme as antTheme, Button, Flex, Popover } from 'antd';

import { DeleteStatus } from './components/DeleteStatus';

interface SettingsPopoverProps {
  statusUid: string;
  stageUid: string;
}

export const StatusOptionsPopover: FC<SettingsPopoverProps> = ({ statusUid, stageUid }) => {
  const [isOpen, setOpen] = useState(false);
  const { token } = antTheme.useToken();

  return (
    <>
      <Popover
        arrow={false}
        trigger="click"
        open={isOpen}
        onOpenChange={setOpen}
        destroyTooltipOnHide
        placement="bottomRight"
        overlayInnerStyle={{ padding: '8px' }}
        content={
          <Flex vertical={true} align="start">
            <DeleteStatus statusUid={statusUid} stageUid={stageUid} />
          </Flex>
        }>
        <Button
          type="text"
          icon={<MoreOutlined style={{ color: token.colorPrimary }} />}
          onClick={() => setOpen(true)}
        />
      </Popover>
    </>
  );
};
