import { EOperations } from '../index';

const EQ = '==' as const;
const NEQ = '!=' as const;
const LE = '=le=' as const;
const LT = '=lt=' as const;
const GE = '=ge=' as const;
const GT = '=gt=' as const;
const IN = '=in=' as const;
const OUT = '=out=' as const;
const EX = '=ex=' as const;

export const mapToValue = (operator: EOperations): string => {
  switch (operator) {
    case EOperations.EQ:
      return EQ;
    case EOperations.NEQ:
      return NEQ;
    case EOperations.LE:
      return LE;
    case EOperations.LT:
      return LT;
    case EOperations.GT:
      return GT;
    case EOperations.GE:
      return GE;
    case EOperations.IN:
      return IN;
    case EOperations.OUT:
      return OUT;
    case EOperations.EX:
      return EX;
    default:
      return EQ;
  }
};
