import React, { ForwardedRef, forwardRef, useState } from 'react';

import { Flex, Select, SelectProps, Typography } from 'antd';

import { EOperations, IInputFilterValue } from '../../index';
import { FilterOperations } from '../FilterOperations';

import { useRecipientsQuery } from './hooks';

interface IProps extends Omit<SelectProps, 'onChange' | 'value' | 'placeholder'> {
  id?: string;
  value?: IInputFilterValue;
  label?: string;
  onChange?: (value: IInputFilterValue) => void;
  ref?: ForwardedRef<any>;
  operations?: EOperations[];
}

export const Component = forwardRef<any, IProps>(({ value, label = '', operations, onChange, ...props }, ref) => {
  const [operationValue, setOperationValue] = useState<EOperations | undefined>();
  const [searchValue, setSearchValue] = useState<any>('');
  const [inputValue, setInputValue] = useState<any>(value?.value);
  const { data, hasNextPage, fetchNextPage, isFetching } = useRecipientsQuery({
    limit: 10,
    search: searchValue,
  });

  const dataSource = data?.pages
    ?.map((data) => data.items || [])
    .flat()
    ?.map((el) => ({
      data: el,
      label: el.name,
      value: el.uid,
    }));

  const triggerChange = (changedValue: IInputFilterValue) => {
    onChange?.({ ...value, ...changedValue });
  };

  const handleChange = (_: any[], values: any[]) => {
    const res = values?.map((el) => ({ value: el.value, label: el.label, data: el.data })) as any;
    setInputValue(res);
    triggerChange({ value: res });
    setSearchValue('');
  };

  const onOptionChange = (operation: EOperations) => {
    setOperationValue(operation);
    setSearchValue(undefined);
    setInputValue(undefined);
    triggerChange({ operation, value: undefined });
  };

  React.useEffect(() => {
    if (!value?.operation && operations?.length) {
      onChange?.({ ...value, operation: operations[0] });
    } else if (value?.operation) {
      setOperationValue(value.operation);
    }
  }, []);

  return (
    <Flex vertical gap={4}>
      {operations?.length && (
        <FilterOperations value={operationValue} onChange={onOptionChange} label={label} operations={operations} />
      )}
      {operationValue !== EOperations.EX && (
        <Select
          value={inputValue}
          mode="multiple"
          maxCount={value?.operation === EOperations.IN || value?.operation === EOperations.OUT ? undefined : 1}
          labelInValue={true}
          dropdownStyle={{ position: 'fixed' }}
          allowClear
          searchValue={searchValue}
          onPopupScroll={(e: any) => {
            const { target } = e;
            if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
              fetchNextPage();
            }
          }}
          style={{ maxWidth: 400 }}
          options={dataSource}
          showSearch
          onSearch={setSearchValue}
          filterOption={false}
          optionRender={(option) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.Text role="img" style={{ margin: 0 }}>
                {option.label}
              </Typography.Text>
              <Typography.Text type="secondary" style={{ margin: 0, fontSize: 12 }}>
                {option.data?.data?.address?.as_string}
              </Typography.Text>
            </div>
          )}
          onChange={(value, option) => handleChange(value, option as any)}
          {...props}
        />
      )}
    </Flex>
  );
});

Component.displayName = 'FilterRecipient';
