import React, { ForwardedRef, forwardRef, useState } from 'react';

import { Flex, Input, InputProps, InputRef } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { EOperations, IInputFilterValue } from '../../index';
import { FilterOperations } from '../FilterOperations';

interface IProps extends Omit<InputProps, 'onChange' | 'value'> {
  id?: string;
  value?: IInputFilterValue;
  label?: string;
  onChange?: (value: IInputFilterValue) => void;
  ref?: ForwardedRef<InputRef>;
  operations?: EOperations[];
}

export const Component = forwardRef<InputRef, IProps>(({ value, label = '', operations, onChange, ...props }, ref) => {
  const [operationValue, setOperationValue] = useState<EOperations | undefined>();
  const [inputValue, setInputValue] = useState(value?.value);
  const commonT = useTranslation(NSKeys.common);

  const triggerChange = (changedValue: IInputFilterValue) => {
    onChange?.({ ...value, ...changedValue });
  };

  const onInputChange = (value: string) => {
    setInputValue(value);
    triggerChange({ value });
  };

  const onOptionChange = (operation: EOperations) => {
    setOperationValue(operation);
    const newInputValue = operation === EOperations.EX ? undefined : inputValue;
    triggerChange({ operation, value: newInputValue });
  };

  React.useEffect(() => {
    if (!value?.operation && operations?.length) {
      onChange?.({ ...value, operation: operations[0] });
    } else if (value?.operation) {
      setOperationValue(value.operation);
    }
  }, []);

  return (
    <Flex vertical gap={4}>
      {operations?.length && (
        <FilterOperations value={operationValue} onChange={onOptionChange} label={label} operations={operations} />
      )}
      {operationValue !== EOperations.EX && (
        <Input
          placeholder={commonT.t('filters.placeholder')}
          ref={ref}
          value={inputValue?.toString()}
          onChange={(e) => onInputChange(e.target.value)}
          {...props}
        />
      )}
    </Flex>
  );
});

Component.displayName = 'FilterInput';
