import React from 'react';

import { Flex } from 'antd';

import { FilterBlock, IField } from '@app/components/FilterBlock';

interface IProps {
  fields: IField[];
  onChange: (value: string) => void;
}

export const Filters: React.FC<IProps> = ({ fields, onChange }) => {
  const handleChange = (query: string) => {
    onChange(query);
  };

  return (
    <Flex justify="flex-end">
      <FilterBlock
        fields={fields}
        showSearchField={false}
        placeholderSearch="Поиск по ID пользователя, ФИО или логину"
        requiredSearchField={false}
        onSubmit={handleChange}
        initialValues={{}}
      />
    </Flex>
  );
};
