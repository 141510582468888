import React from 'react';

import { HolderOutlined } from '@ant-design/icons';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Button, ColorPicker, Flex, Skeleton, Space, Typography } from 'antd';

import { useUpdateStatuseQuery } from '../../../../../../hooks';

import { StatusOptionsPopover } from './components/StatusOptionsPopover';

interface IProps {
  name: string;
  color: string;
  statusUid: string;
  stageUid: string;
}

export const StatusBlock: React.FC<IProps> = ({ name, color, statusUid, stageUid }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: statusUid });
  const [editableStr, setEditableStr] = React.useState(name);
  const [editableColor, setEditableColor] = React.useState(color);
  const { mutate, status } = useUpdateStatuseQuery();

  React.useEffect(() => {
    setEditableStr(name);
  }, [name]);

  React.useEffect(() => {
    setEditableColor(color);
  }, [color]);

  const onEditName = (value: string) => {
    if (name !== value) {
      mutate({ status_uid: statusUid, data: { name: value } });
    }
  };
  const handleColorChange = (value: boolean) => {
    if (!value && color !== editableColor) {
      mutate({ status_uid: statusUid, data: { color: editableColor } });
    }
  };

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? '0.6' : '1',
    zIndex: 100,
    width: '100%',
  };

  return (
    <div style={style} ref={setNodeRef}>
      <Flex style={{ marginBottom: 4, width: '100%' }} align="center">
        {status === 'loading' ? (
          <Skeleton.Input active block={true} />
        ) : (
          <Flex style={{ width: '100%' }} justify="space-between">
            <Space size={10}>
              <Button
                type="text"
                icon={<HolderOutlined style={{ opacity: 0.6 }} />}
                {...attributes}
                {...listeners}
                style={{ cursor: 'grab' }}
              />
              <ColorPicker
                size="small"
                value={editableColor}
                onChangeComplete={(color) => setEditableColor(color.toHex())}
                onOpenChange={handleColorChange}
              />
              <Typography.Text editable={{ onChange: onEditName }} style={{ width: '100%', marginTop: 0 }}>
                {editableStr}
              </Typography.Text>
            </Space>
            <StatusOptionsPopover statusUid={statusUid} stageUid={stageUid} />
          </Flex>
        )}
      </Flex>
    </div>
  );
};
