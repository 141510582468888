import React from 'react';

import { StyledTag } from './styles';

interface IProps {
  color: string;
  name: string;
  small?: boolean;
}

export const StatusTag: React.FC<IProps> = ({ color, name, small = false }) => {
  return (
    <StyledTag $color={color} $small={small}>
      {name}
    </StyledTag>
  );
};
