export const isEmptyObject = (o: any, exceptKey?: string): boolean => {
  if (!o) {
    return true;
  }
  return Object.keys(o).every(function (key) {
    if (exceptKey && key === exceptKey) {
      return true;
    }
    return o[key] === '' || o[key] === null || o[key] === undefined;
  });
};
