import React, { useRef } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, InputRef, RefSelectProps, Row, Typography } from 'antd';

import { IField } from '../../interfaces';

import { Container, Label, RemoveButton } from './styles';

interface IProps {
  field: IField;
  newFilter: boolean;
  onRemove: () => void;
  form?: FormInstance;
}

export const PopoverField: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const fieldValue = Form.useWatch(props.field.name, props.form);
  const ref = useRef<RefSelectProps | InputRef>(null);
  const clonedElement = React.cloneElement(props.field.inputField || <Input placeholder={props.field.label} />, {
    ref,
    getPopupContainer: (trigger: any) => trigger?.parentNode,
  });

  React.useEffect(() => {
    setOpen(props.newFilter);
  }, [props.newFilter]);

  React.useEffect(() => {
    if (open && ref?.current?.focus) {
      ref?.current?.focus();
    }
  }, [open]);

  const displayValue = React.useMemo(() => {
    if (fieldValue) {
      if (props.field.labelInValue && props.field.renderDisplayValue) {
        return props.field.renderDisplayValue(fieldValue);
      }

      return props.field.renderDisplayValue ? props.field.renderDisplayValue(fieldValue) : `${fieldValue}`;
    }
  }, [fieldValue]);

  return (
    <Container
      placement="bottomLeft"
      arrow={false}
      destroyTooltipOnHide={true}
      content={
        <Form.Item name={props.field.name} rules={props.field.rules} noStyle>
          {clonedElement}
        </Form.Item>
      }
      trigger="click"
      open={open}
      onOpenChange={setOpen}>
      <Button shape="round" style={{ paddingRight: 5 }}>
        {/* Используется для инициализации формы */}
        <Form.Item name={props.field.name} style={{ display: 'none' }}>
          {props.field.inputField || <Input placeholder={props.field.label} />}
        </Form.Item>
        <Row align="middle" justify="start">
          <Label $hasValue={!!fieldValue}>{props.field.label}</Label>
          <Typography.Text ellipsis={{ tooltip: displayValue }} style={{ marginLeft: 5 }}>
            {displayValue}
          </Typography.Text>
          {props.field.require ? (
            <Typography.Text style={{ marginRight: 7, marginLeft: 5 }} type="danger">
              *
            </Typography.Text>
          ) : (
            <RemoveButton
              onClick={(e) => {
                e.stopPropagation();
                props.onRemove();
              }}>
              <CloseOutlined style={{ fontSize: 10 }} />
            </RemoveButton>
          )}
        </Row>
      </Button>
    </Container>
  );
};
