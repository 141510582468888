import { useQuery } from '@tanstack/react-query';

import { IOrderByProcessParams } from '@app/models/interfaces';

import Api from '@app/services/api';

export const useGetOrdersByProcessQuery = ({ process_uid, offset, limit, query, sort }: IOrderByProcessParams) => {
  return useQuery({
    queryKey: ['getOrdersByProcess', process_uid, limit, offset, query, sort],
    queryFn: () => Api.orders.getOrdersByProcess({ process_uid, offset, limit, query, sort }),
    enabled: !!process_uid,
  });
};
