import React, { ForwardedRef, forwardRef, useState } from 'react';

import { Flex, Select, SelectProps } from 'antd';

import { EOperations, IInputFilterValue } from '../../index';
import { FilterOperations } from '../FilterOperations';

import { useGetCatalogsQuery } from './hooks/useGetCatalogsQuery';

interface IProps extends Omit<SelectProps, 'onChange' | 'value' | 'placeholder'> {
  id?: string;
  value?: IInputFilterValue;
  label?: string;
  onChange?: (value: IInputFilterValue) => void;
  ref?: ForwardedRef<any>;
  operations?: EOperations[];
}

export const Component = forwardRef<any, IProps>(({ value, label = '', operations, onChange, ...props }, ref) => {
  const [operationValue, setOperationValue] = useState<EOperations | undefined>();
  const [inputValue, setInputValue] = useState<any>(value?.value);
  const { data, hasNextPage, fetchNextPage, isFetching } = useGetCatalogsQuery({
    limit: 10,
  });
  const dataSource = data?.pages?.map((data) => data.items || []).flat() || [];

  const triggerChange = (changedValue: IInputFilterValue) => {
    onChange?.({ ...value, ...changedValue });
  };

  const onInputChange = (_: any[], values: any[]) => {
    const res = values?.map((el) => ({ value: el.value, label: el.label, data: el.data })) as any;
    setInputValue(res);
    triggerChange({ value: res });
  };

  const onOptionChange = (operation: EOperations) => {
    setOperationValue(operation);
    setInputValue(undefined);
    triggerChange({ operation, value: undefined });
  };

  React.useEffect(() => {
    if (!value?.operation && operations?.length) {
      onChange?.({ ...value, operation: operations[0] });
    } else if (value?.operation) {
      setOperationValue(value.operation);
    }
  }, []);

  return (
    <Flex vertical gap={4}>
      {operations?.length && (
        <FilterOperations value={operationValue} onChange={onOptionChange} label={label} operations={operations} />
      )}
      {operationValue !== EOperations.EX && (
        <Select
          mode="tags"
          maxCount={value?.operation === EOperations.IN || value?.operation === EOperations.OUT ? undefined : 1}
          labelInValue={true}
          dropdownStyle={{ position: 'fixed' }}
          allowClear
          onPopupScroll={(e: any) => {
            const { target } = e;
            if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
              fetchNextPage();
            }
          }}
          style={{ maxWidth: 400 }}
          options={dataSource?.map((el) => ({
            label: el.name,
            value: el.uid,
          }))}
          value={inputValue}
          notFoundContent={null}
          filterOption={false}
          onChange={(value, option) => onInputChange(value, option as any)}
          {...props}
        />
      )}
    </Flex>
  );
});

Component.displayName = 'FilterCatalog';
