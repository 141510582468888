import React, { FC, useState } from 'react';

import { Flex, Layout } from 'antd';
import { generatePath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CreateProcessModal } from '@pages/Processes/components/ControlProcess/components/CreateProcessModal';

import { useGetProcessQuery } from '@pages/Processes/hooks';

import { ControlProcess } from '../../../../components/ControlProcess';
import { SettingsPopover } from '../../../../components/SettingsPopover';
import { ViewSwitch } from '../../../../components/ViewSwitch';
import { ViewType } from '../../../../data/interfaces';

import { BoardContainer } from './components/BoardContainer';
import { ExportButton } from './components/ExportButton';
import { TableContainer } from './components/TableContainer';

export const List: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ processId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: processData, isLoading: isProcessLoading } = useGetProcessQuery(params?.processId || '');
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleSelect = (processUid: string) => {
    const { search } = location;
    navigate(generatePath('/dashboard/processes/:processId/orders' + (search ?? ''), { processId: processUid }));
  };

  const viewType = searchParams.get('view');

  return (
    <>
      <Layout.Header>
        <Flex justify="space-between" align="center" wrap style={{ minHeight: 64 }}>
          <Flex align="center" gap={16}>
            <ControlProcess
              onSelect={handleSelect}
              activeProcessId={params.processId}
              onCreate={() => setCreateModalOpen(true)}
            />
          </Flex>
          <Flex align="center" gap={16}>
            <ViewSwitch />
            {processData?.$permissions.manage && !isProcessLoading && <SettingsPopover />}
            <ExportButton />
          </Flex>
        </Flex>
      </Layout.Header>

      <CreateProcessModal open={createModalOpen} onClose={() => setCreateModalOpen(false)} />
      {viewType === ViewType.BOARD ? <BoardContainer /> : <TableContainer key={params.processId} />}
    </>
  );
};
