import { Button } from 'antd';
import styled from 'styled-components';

export const SelectItem = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 2px;
  padding: 5px 8px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;
