import React, { useEffect } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input } from 'antd';

import { isEmptyObject } from '@app/services/isEmptyObject';

interface IProps {
  form: FormInstance;
  placeholderSearch?: string;
  minLength?: number;
}

export const SearchBlock: React.FC<IProps> = ({ form, placeholderSearch, minLength = 3 }) => {
  const searchValue = Form.useWatch('search', form);
  const formValues = Form.useWatch([], form);
  const [valid, setValid] = React.useState(false);

  useEffect(() => {
    const isEmptyExceptSearch = isEmptyObject(formValues, 'search');

    if (searchValue?.length < minLength) {
      setValid(!isEmptyExceptSearch);
    } else {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setValid(true);
        },
        () => {
          setValid(false);
        }
      );
    }
  }, [formValues, searchValue]);

  return (
    <div style={{ marginBottom: 24 }}>
      <Form.Item name="search" validateTrigger={['onChange', 'onBlur']} noStyle>
        <Input.Search
          placeholder={placeholderSearch || 'Поиск по ID пользователя, ФИО или логину'}
          allowClear
          enterButton={
            <Button type="primary" disabled={!valid} icon={<SearchOutlined />} htmlType="submit">
              Поиск
            </Button>
          }
          size="large"
        />
      </Form.Item>
    </div>
  );
};
