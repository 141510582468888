import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IParams {
  stage_uid: string;
  status_uid: string;
}

export const useDeleteSageStatusQuery = () => {
  const validationT = useTranslation(NSKeys.validation);
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ status_uid, stage_uid }: IParams) => {
      await Api.orders.deleteStageStatus(stage_uid, status_uid);
    },
    onSuccess: async (_) => {
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
      await client.invalidateQueries(['getProcess']);
    },
    onError: async (e: any) => {
      message.error(validationT.t(e?.response?.data?.errors?.[0]?.code, { defaultValue: commonT.t('error400') }));
      await client.invalidateQueries(['getProcess']);
    },
  });
};
