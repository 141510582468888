const getPrefix = (order: string): string => (order === 'ascend' ? '+' : '-');

export const getSortValues = (data: any[] | any) => {
  console.log(data);
  if (Array.isArray(data)) {
    return data.reduce((acc, el) => [...acc, `${getPrefix(el.order)}${el.columnKey}`], []);
  } else if (typeof data === 'object' && data.order) {
    return [`${getPrefix(data.order)}${data.columnKey}`];
  }
  return [];
};
