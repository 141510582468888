import React, { ForwardedRef, forwardRef, useState } from 'react';

import { DatePicker, DatePickerProps, Flex } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { EOperations, IInputFilterValue } from '../../index';
import { FilterOperations } from '../FilterOperations';

interface IProps extends Omit<DatePickerProps, 'onChange' | 'value' | 'format'> {
  id?: string;
  value?: IInputFilterValue;
  label?: string;
  format: string;
  keepIso?: boolean;
  onChange?: (value: IInputFilterValue) => void;
  ref?: ForwardedRef<any>;
  operations?: EOperations[];
}

export const Component = forwardRef<any, IProps>(
  ({ value, label = '', keepIso = false, format, operations, onChange, ...props }, ref) => {
    const [operationValue, setOperationValue] = useState<EOperations | undefined>();
    const [dateValue, setDateValue] = useState<null | Dayjs>(value?.value ? dayjs(value.value as any) : null);
    const commonT = useTranslation(NSKeys.common);

    const triggerChange = (changedValue: IInputFilterValue) => {
      onChange?.({ ...value, ...changedValue });
    };

    const onInputChange = (value: Dayjs) => {
      setDateValue(dayjs(value));
      triggerChange({ value: keepIso ? value.toISOString().slice(0, -1) : value.format(format) });
    };

    const onOptionChange = (operation: EOperations) => {
      setOperationValue(operation);
      if (operation === EOperations.EX) {
        return triggerChange({ operation, value: undefined });
      }
      return triggerChange({ operation });
    };

    React.useEffect(() => {
      if (!value?.operation && operations?.length) {
        onChange?.({ ...value, operation: operations[0] });
      } else if (value?.operation) {
        setOperationValue(value.operation);
      }
    }, []);

    return (
      <Flex vertical gap={4}>
        {operations?.length && (
          <FilterOperations value={operationValue} onChange={onOptionChange} label={label} operations={operations} />
        )}
        {operationValue !== EOperations.EX && (
          <DatePicker
            ref={ref}
            autoFocus={true}
            placeholder={commonT.t('filters.placeholder')}
            value={dateValue}
            onChange={onInputChange}
            {...props}
          />
        )}
      </Flex>
    );
  }
);

Component.displayName = 'FilterDate';
