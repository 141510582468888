import React, { FC } from 'react';

import { Flex, Select, SelectProps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { EOperations } from './interfaces';

interface IProps extends Omit<SelectProps, 'onChange' | 'value'> {
  label: string;
  id?: string;
  value?: EOperations;
  onChange?: (value: EOperations) => void;
  operations: EOperations[];
}

export const FilterOperations: FC<IProps> = ({ value, onChange, operations, label, ...props }) => {
  const commonT = useTranslation(NSKeys.common);

  // console.log(value, EOperations, EOperations[EOperations[value as any] as any]);

  return (
    <Flex align="center" gap={4}>
      <Typography.Text type="secondary" style={{ fontSize: 12 }}>
        {label}
      </Typography.Text>
      <Select
        variant="borderless"
        size="small"
        value={value}
        onChange={onChange}
        options={operations.map((el) => ({
          label: commonT.t(`filters.${el.toLowerCase()}` as any, { defaultValue: el }),
          value: el,
        }))}
        {...props}
        style={{ flex: 1, minWidth: 160 }}
      />
    </Flex>
  );
};
