import React from 'react';

import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Flex, Image, Space } from 'antd';

interface IProps {
  imageSize?: number;
  imageList: { previewSrc: string; fullSrc: string }[];
}

export const ImagesList: React.FC<IProps> = ({ imageList, imageSize = 100, ...rest }) => {
  const [current, setCurrent] = React.useState(0);

  const onDownload = () => {
    const { fullSrc } = imageList[current];
    const suffix = fullSrc.slice(fullSrc.lastIndexOf('.'));
    const filename = Date.now() + suffix;

    fetch(fullSrc)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        link.remove();
      });
  };

  return (
    <Image.PreviewGroup
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset },
          }
        ) => (
          <Space
            size={12}
            className="toolbar-wrapper"
            style={{ padding: '20px', backgroundColor: '#00000042', borderRadius: '10px' }}>
            <DownloadOutlined onClick={onDownload} />
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            <UndoOutlined onClick={onReset} />
          </Space>
        ),
        onChange: (index) => {
          setCurrent(index);
        },
      }}>
      <Flex style={{ width: '100%' }} wrap justify="flex-end" gap={8}>
        {imageList.map((item, i) => (
          <Image
            key={item.fullSrc}
            width={imageSize}
            src={item.fullSrc}
            onClick={(e) => {
              e.stopPropagation();
              setCurrent(i);
            }}
          />
        ))}
      </Flex>
    </Image.PreviewGroup>
  );
};
