import React, { ForwardedRef, forwardRef, useState } from 'react';

import { Button, Divider, Flex, Select, SelectProps, Typography } from 'antd';

import { StatusTag } from '@components/StatusTag';

import { EOperations, IInputFilterValue } from '../../index';
import { FilterOperations } from '../FilterOperations';

import { useGetProcessQuery } from './hooks';

interface IProps extends Omit<SelectProps, 'onChange' | 'value' | 'placeholder'> {
  processUid: string;
  value?: IInputFilterValue;
  label?: string;
  onChange?: (value: IInputFilterValue) => void;
  ref?: ForwardedRef<any>;
  operations?: EOperations[];
}

export const Component = forwardRef<any, IProps>(
  ({ processUid, value, label = '', operations, onChange, ...props }, ref) => {
    const [options, setOptions] = useState<any[]>([]);
    const [operationValue, setOperationValue] = useState<EOperations | undefined>();
    const [inputValue, setInputValue] = useState<any>(value?.value);
    const { data, isFetching } = useGetProcessQuery(processUid);

    React.useEffect(() => {
      if (data) {
        const res = data?.stages
          ?.map((stage) => stage.statuses.map((status) => ({ value: status.uid, data: status, label: status.name })))
          .flat();

        setOptions(res);
      }
    }, [data]);

    const triggerChange = (changedValue: IInputFilterValue) => {
      onChange?.({ ...value, ...changedValue });
    };

    const handleChange = (_: any[], values: any[]) => {
      const res = values?.map((el) => ({ value: el.value, label: el.label, data: el.data })) as any;
      setInputValue(res);
      triggerChange({ value: res });
    };

    const onOptionChange = (operation: EOperations) => {
      setOperationValue(operation);
      setInputValue(undefined);
      triggerChange({ operation, value: undefined });
    };

    React.useEffect(() => {
      if (!value?.operation && operations?.length) {
        onChange?.({ ...value, operation: operations[0] });
      } else if (value?.operation) {
        setOperationValue(value.operation);
      }
    }, []);

    return (
      <Flex vertical gap={4}>
        {operations?.length && (
          <FilterOperations value={operationValue} onChange={onOptionChange} label={label} operations={operations} />
        )}
        {operationValue !== EOperations.EX && (
          <Select
            value={inputValue}
            loading={isFetching}
            mode="multiple"
            maxCount={value?.operation === EOperations.IN || value?.operation === EOperations.OUT ? undefined : 1}
            labelInValue={true}
            dropdownStyle={{ position: 'fixed' }}
            allowClear
            showSearch={false}
            style={{ maxWidth: 400 }}
            filterOption={false}
            options={options}
            optionRender={(option) => {
              console.log(option);
              return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '25px' }}>
                  <StatusTag small color={option.data.data.color} name={option.label as string} />
                </div>
              );
            }}
            onChange={(value, option) => handleChange(value, option as any)}
            {...props}
          />
        )}
      </Flex>
    );
  }
);

Component.displayName = 'OrderStatusSelect';
