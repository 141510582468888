import { Typography } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Typography.Text)<{ $color: string; $small: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  padding: ${({ $small }) => ($small ? '3px 10px 3px 15px' : '5px 10px 5px 15px')};
  border-radius: 3px;

  ::before {
    position: absolute;
    content: '';
    left: 2px;
    top: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ $color }) => $color};
  }
`;
