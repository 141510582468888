import React from 'react';

import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useDeleteProcessStageQuery } from '../../../../../../../../../../hooks';

interface IProps {
  process_uid: string;
  stage_uid: string;
}

export const DeleteStatus: React.FC<IProps> = ({ process_uid, stage_uid }) => {
  const commonT = useTranslation(NSKeys.common);

  const { mutate, status } = useDeleteProcessStageQuery();

  return (
    <Popconfirm
      title={commonT.t('deleteConfirm')}
      onConfirm={(e) => {
        e?.stopPropagation();
        mutate({ stage_uid, process_uid });
      }}
      okText={commonT.t('yes')}>
      <Button
        loading={status === 'loading'}
        type={'text'}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {commonT.t('buttonDelete')}
      </Button>
    </Popconfirm>
  );
};
