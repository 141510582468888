import React from 'react';

import { DragOutlined, HolderOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Button, Flex, FormInstance } from 'antd';

import { FieldStructure } from '@app/models/interfaces';
import { RenderFieldByType } from '@components/RenderFieldByType';

import { FieldOptionsPopover } from './components/FieldOptionsPopover';

interface IProps {
  field: FieldStructure;
  form: FormInstance;
}

export const FieldRender: React.FC<IProps> = ({ field, form }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: field.uid });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? '0.6' : '1',
    zIndex: 100,
  };

  return (
    <div style={style} ref={setNodeRef}>
      <Flex style={{ position: 'relative' }}>
        <Button
          type="text"
          icon={<HolderOutlined style={{ opacity: 0.6 }} />}
          {...attributes}
          {...listeners}
          style={{ cursor: 'grab' }}
        />
        <div style={{ flex: 1 }}>
          <RenderFieldByType
            form={form}
            type={field.type}
            label={field.name}
            name={field.uid}
            required={field.is_required}
            preview
          />
        </div>
        <div style={{ position: 'absolute', right: 0, top: 0 }}>
          <Flex>{!field.is_system && <FieldOptionsPopover field={field} />}</Flex>
        </div>
      </Flex>
    </div>
  );
};
