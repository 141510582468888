import React from 'react';

import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useDeleteSageStatusQuery } from '../../../../../../../../../../hooks';

// import { useDeleteField } from '../../../../../../../../../../../../hooks';

interface IProps {
  statusUid: string;
  stageUid: string;
}

export const DeleteStatus: React.FC<IProps> = ({ statusUid, stageUid }) => {
  const commonT = useTranslation(NSKeys.common);

  const { mutate, status } = useDeleteSageStatusQuery();

  return (
    <Popconfirm
      title={commonT.t('deleteConfirm')}
      onConfirm={(e) => {
        e?.stopPropagation();
        mutate({ stage_uid: stageUid, status_uid: statusUid });
      }}
      okText={commonT.t('yes')}>
      <Button
        loading={status === 'loading'}
        type={'text'}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {commonT.t('buttonDelete')}
      </Button>
    </Popconfirm>
  );
};
